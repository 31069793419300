.transactionsCtaContainer {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .transactionsCtaContainer {
    display: none;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.backBtn {
  padding: 0px !important;
}

@media screen and (max-width: 768px) {
  .backBtn {
    display: none !important;
  }
}
