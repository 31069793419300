.container_style {
  border-radius: 5px;
  border: 1.5px solid #dfdfdf;
  background: #fff;
}

.recording_container_style {
  display: flex;
  gap: 12px;
  padding: 15px 12px;
  align-items: center;
  border-bottom: 1.5px solid #dfdfdf;
}

.recording_text_style {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.info_container_style {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 12px;
}

.section_container_style {
  display: flex;
  gap: 5px;
}

.section_heading_style {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.section_text_style {
  color: rgba(0, 0, 0, 0.85);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
