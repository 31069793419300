.tooltip_wrapper {
  position: relative !important;
  z-index: 12321213 !important;
  pointer-events: none;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1213123;
  top: 20px !important;
}
