.tooltip_menu_style {
  z-index: 12345;
  max-width: 150px;
  background-color: #ffffff;
  box-shadow: 1px 2px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.tooltip_menu_item_style {
  font-size: 14px;
  box-sizing: border-box;
  min-height: 26px;
  padding: 5px 9px;
  gap: 8.5px;
  color: #272522;
  display: flex;
  align-items: center;
  white-space: break-spaces;
  width: auto;
  overflow: hidden;
  text-align: left;
}

.hide_menu {
  visibility: hidden;
}

.menu_style {
  margin-top: 20px !important;
}

.hide_tooltip_menu_style {
  display: none;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1213123;
  bottom: 10px !important;
}

@media screen and (max-width: 768px) {
  .tooltip {
    right: 20px;
  }
}
