@media screen and (max-width: 768px) {
  .body {
    padding: 0;
  }
}

.tooltip_wrapper {
  position: relative !important;
  z-index: 12321213 !important;
  pointer-events: none;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1213123;
  top: 20px !important;
}

.save_changes_style {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 12px 16px;
}

.save_changes_tooltip_wrapper {
  z-index: 1234 !important;
}
.create_new_section_label {
  color: #493ab1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  white-space: nowrap;
}

.tooltip_header {
  visibility: hidden;
  position: absolute;
  z-index: 1213123;
  top: 20px !important;
}

@media screen and (max-width: 768px) {
  .tooltip_header {
    top: 15px !important;
  }

  .tooltip {
    top: 15px !important;
    right: 10px !important;
  }

  .mobile_icon_text_container {
    display: flex;
    align-items: center;
    gap: 14px;
  }
}

@media screen and (min-width: 768px) {
  .save_changes_style {
    display: none;
  }
}
