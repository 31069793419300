.input_wrapper_style {
  margin-top: 16px;
  margin-bottom: 24px;
}

.modal_style {
  width: 100% !important;
  max-width: 410px !important;
  border-radius: 14px !important;
}

.input_label_style {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input_style {
  height: 40px !important;
  width: 100%;
}

.modal_button_style {
  justify-content: space-between !important;
}

.error_text_style {
  color: #bf2600;
  font-size: 11px;
}
