.root_dialoge_style {
  width: calc(100% - 50px) !important;
  max-width: 100% !important;
}

.video_wrapper {
  width: inherit;
  height: inherit;
  position: relative;
}

.clear_icon_style {
  color: white !important;
  position: absolute !important;
  top: 5px;
  right: 5px;
  cursor: pointer !important;
  z-index: 10;
}
