.modalPaper {
  max-width: 600px;
  width: 600px;
}

.container {
  padding: 16px 24px;
  width: 550px;
}

.loaderWrapper {
  padding: 24px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  padding: 10px 10px 10px 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.container .row + .row {
  /* earlier when js file was there -> theme?.palette?.secondary?.shade_100 */
  border-top: 1px solid #e7e6e4;
}

.rowTitle {
  flex-shrink: 0;
  line-height: 20px;
  font-weight: 500;
}

.rowValue {
  line-height: 19px;
  font-weight: 400;
  word-break: break-all;
  text-align: right;
}

.remarksRow {
  flex-direction: column;
  gap: 0;
  padding-bottom: 0;
  justify-content: flex-start;
  align-items: stretch;
}

.lineField {
  font-size: 16px;
  line-height: 19px;
}

.action {
  color: #493ab1;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.action:hover {
  text-decoration: underline;
}

.desktopDetailModal {
  min-width: 500px;
}

.moreContentModal {
  padding: 24px !important;
}

.detailModalHeader > h2 > div,
.mobileDetailHeader {
  height: 81px !important;
}

@media (max-width: 768px) {
  .container {
    width: 100% !important;
  }
}
