.recording_section_container {
  overflow-y: auto;
  max-height: 350px;
  margin: 20px 0;
}

.recording_section_inner_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-right: 5px;
}

.recording_section_inner_container > div:nth-child(even) {
  background-color: #f5f5f5;
}

.modal_sub_text_style {
  color: #272522;
  margin-top: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.28px;
  max-width: 530px;
}

.note_container_style {
  margin: 10px 0 20px 0;
}

.note_heading_style {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.24px;
}

.note_description_style {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
}

.toast_heading_style {
  color: #00ab71;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.32px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .recording_section_container {
    max-height: 200px;
  }
}
