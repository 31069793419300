.dropdown_text_style {
  color: #272522;
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  align-items: center;
  padding: 8px 24px 8px 12px;
  line-height: 13px;
  width: 150px;
  border: 1.5px solid rgb(207, 205, 201);
  border-radius: 5px;
  position: relative;
}

.dropdown_text_label_style {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.placeholder_style {
  color: #8a8a8a;
}

.icon_style {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 6px;
  position: absolute;
  pointer-events: none;
}

.select_container_style {
  position: relative;
}

.menu_container_style {
  width: 185px !important;
}
